<template>
    <a-card
        :bordered="false"
        class="header-solid h-full card-profile-information"
        :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }"
        :headStyle="{ paddingRight: 0 }"
    >
        <template #title>
            <h6 class="font-semibold m-0">{{ $t('cms.profile_information') }}</h6>
        </template>
        <hr class="mb-5" />
        <a-form class="profile__form" :form="form">
            <a-form-item :label="$t('form.email')" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                <a-input type="text" v-decorator="['email', { initialValue: userInfo.email }]" disabled />
            </a-form-item>
            <a-form-item :label="$t('form.full_name')" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                <a-input
                    type="text"
                    :max-length="255"
                    v-decorator="[
                        'full_name',
                        {
                            initialValue: userInfo.full_name,
                            rules: [{ required: true, message: $t('form.fullname_required'), whitespace: true }],
                        },
                    ]"
                />
            </a-form-item>
            <!-- <a-form-item :label="$t('form.gender')" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                <a-radio-group v-decorator="['gender', { initialValue: userInfo.gender }]">
                    <a-radio value="MALE"> {{ $t('form.male') }} </a-radio>
                    <a-radio value="FEMALE"> {{ $t('form.female') }} </a-radio>
                    <a-radio value="OTHER"> {{ $t('form.other') }} </a-radio>
                </a-radio-group>
            </a-form-item> -->
            <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20, offset: 4 }">
                <a-button :style="{ minWidth: '145px' }" @click="onUpdate"> {{ $t('form.update') }} </a-button>
            </a-form-item>
        </a-form>
    </a-card>
</template>

<script>
import * as UserAPI from '../../../api/userAPI';
import { Employee } from '../../../api';
import { mixinToasts, mixinLoadings } from '../../../mixins';
import { checkIsMaster } from '../../../utils';

export default {
    mixins: [mixinToasts, mixinLoadings],
    props: {
        userInfo: { type: Object, required: true },
    },
    data() {
        return {
            form: this.$form.createForm(this, { name: 'update_profile' }),
        };
    },
    methods: {
        onUpdate() {
            this.form.validateFields(async (error, values) => {
                if (!error) {
                    try {
                        this.showLoading();
                        let res = {};

                        if (checkIsMaster(this.userInfo)) {
                            res = await Employee.updateOne(this.userInfo?.id, {
                                ...values,
                                full_name: values.full_name.trim(),
                            });
                        } else {
                            res = await UserAPI.update(this.userInfo?.id, {
                                ...values,
                                full_name: values.full_name.trim(),
                            });
                        }

                        const data = res?.results?.object;
                        this.$store.dispatch('updateInfo', data);
                        this.form.setFieldsValue({ email: data.email, full_name: data.full_name, gender: data.gender });
                        setTimeout(() => {
                            this.hideLoading();
                            this.showToast('success', this.$t('profile_page.successfully_updated'));
                        }, 500);
                    } catch (error) {
                        this.hideLoading();
                        this.showError(error);
                    }
                }
            });
        },
    },
};
</script>
