<template>
    <div>
        <!-- Header Background Image -->
        <div class="profile-nav-bg" style="background-image: url('images/bg-profile.jpg')"></div>
        <!-- / Header Background Image -->

        <!-- User Profile Card -->
        <a-card :bordered="false" class="card-profile-head" :bodyStyle="{ padding: 0 }">
            <template #title>
                <a-row type="flex" align="middle">
                    <a-col :span="24" :md="12" class="col-info">
                        <a-avatar :size="74" shape="square" :src="userInfo.avatar" />
                        <div class="avatar-info">
                            <h4 class="font-semibold m-0 text-truncate" style="max-width: 500px;">{{ userInfo.full_name }}
                            </h4>
                            <p>{{ $t(`form.manager`) }}</p>
                        </div>
                    </a-col>
                    <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                        <ChangePassword :visible="visible" :onClose="openChangePass(false)" />
                    </a-col>
                </a-row>
            </template>
        </a-card>
        <!-- User Profile Card -->

        <a-row type="flex" :gutter="24">
            <!-- Profile Information Column -->
            <a-col :span="20" class="mb-24 mx-auto">
                <!-- Profile Information Card -->
                <CardProfileInformation :userInfo="userInfo"></CardProfileInformation>
                <!-- Profile Information Card -->
            </a-col>
            <!-- Profile Information Column -->
        </a-row>
    </div>
</template>

<script>
import ChangePassword from '../../components/Web/ChangePassword';
import CardProfileInformation from '../../components/CMS/Cards/CardProfileInformation';

export default {
    components: {
        ChangePassword,
        CardProfileInformation,
    },
    data() {
        return {
            userInfo: this.$store.getters.getUserInfo,
            visible: false,
        };
    },
    watch: {
        '$store.state.auth.userInfo': function () {
            this.userInfo = this.$store.getters.getUserInfo;
        },
    },
    methods: {
        openChangePass(value) {
            this.visible = value;
        },
    },
};
</script>
